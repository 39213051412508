//Colors
$yellow: #ffce14;
$red: #e20714;
$light-red: #fef3eb;
$green: #94c01f;
$light-green: #f8fced;
$blue: #019ee3;
$dark-blue: #2c3f58;
$dark-blue-2: #35425b;
$white: #ffffff;
$light-grey: #f0f4f8;
$grey: #d5d8db;
$blue-grey: #778699;
