@use '@angular/material' as mat;
// IMPORTS
@import 'assets/fonts/GothamLight/gotham-light.css';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '@ag-grid-community/styles/ag-grid.css';
@import '@ag-grid-community/styles/ag-theme-balham.css';
@import 'primeng/resources/themes/nova-alt/theme.css';
@import 'primeng/resources/primeng.min.css';
@import 'primeicons/primeicons.css';
@import 'bootstrap/scss/bootstrap.scss';

@import 'colors';
@import 'variables';

@include mat.all-component-typographies();
@include mat.core();

$md-primary: (
  50 : #e1f3fc,
  100 : #b3e2f7,
  200 : #80cff1,
  300 : #4dbceb,
  400 : #27ade7,
  500 : #019fe3,
  600 : #0197e0,
  700 : #018ddc,
  800 : #0183d8,
  900 : #0072d0,
  A100 : #f8fcff,
  A200 : #c5e2ff,
  A400 : #92c8ff,
  A700 : #79bbff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-darkblue: (
  50 : #e6e8eb,
  100 : #c0c5cd,
  200 : #969fac,
  300 : #6b798a,
  400 : #4c5c71,
  500 : #2c3f58,
  600 : #273950,
  700 : #213147,
  800 : #1b293d,
  900 : #101b2d,
  A100 : #6c9fff,
  A200 : #397eff,
  A400 : #065dff,
  A700 : #0052eb,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$my-primary: mat.define-palette($md-darkblue, 500);
$my-accent: mat.define-palette($md-primary, 500);

$my-font: mat.define-typography-config(
  $font-family: "'GothamLight', 'arial', 'sans-serif'",
  $button: mat.define-typography-level(14px, 14px, 700, $letter-spacing: 0px),
);

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
  ),
  typography: $my-font,
  density: 0,
));

$my-inversed-theme: mat.define-light-theme((
  color: (
    primary: $my-accent,
    accent: $my-primary,
  ),
  typography: $my-font,
  density: 0,
));

// The following mixins include base theme styles that are only needed once per application. These
// theme styles do not depend on the color, typography, or density settings in your theme. However,
// these styles may differ depending on the theme's design system. Currently all themes use the
// Material 2 design system, but in the future it may be possible to create theme based on other
// design systems, such as Material 3.
//
// Please note: you do not need to include the 'base' mixins, if you include the corresponding
// 'theme' mixin elsewhere in your Sass. The full 'theme' mixins already include the base styles.
//
// To learn more about "base" theme styles visit our theming guide:
// https://material.angular.io/guide/theming#theming-dimensions
@include mat.core-theme($my-theme);
@include mat.all-component-bases($my-theme);
@include mat.tabs-theme($my-inversed-theme);
@include mat.checkbox-theme($my-inversed-theme);

* {
  font-family: 'GothamLight', 'arial', sans-serif;
}

// Import toastr
@import 'ngx-toastr/toastr';
// if you'd like to use it without importing all of bootstrap it requires
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';


// Test
$default-background: $white;
$odd-row-background-color: $white;
$border-color: rgba(255, 255, 255, 0);
$row-border-width: 0px;

// Ag-grid Styles

.ag-row .ag-cell {
  display: flex;
  justify-content: flex-start; /* align horizontal */
  align-items: center;
  color: $dark-blue;

  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-size: 0.7rem;
}

.ag-header-cell {
  justify-content: left; /* align horizontal */
  background-color: #fafbfd;
}

.ag-header-cell-text {
  // margin-top: auto;
  // margin-bottom: auto;
  color: $blue-grey;
  font-weight: 400;
  font-size: 0.8rem;
}

.ag-header-icon .ag-icon {
  vertical-align: middle;
  color: $blue-grey;
}

.ag-floating-filter-input {
  border-style: solid;
  border-width: 1px;
  border-color: $blue-grey;
}

.ag-theme-balham .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
  border: none !important;
}

.ag-theme-balham .ag-checkbox-input-wrapper:focus-within {
  box-shadow: none !important;
}

// Styles

button.mat-mdc-button {
  border: 1px solid #6cadce;
}

.btn-primary {
  background-color: $blue;
  border-color: $blue;

  &:hover {
    background-color: rgba($blue, 0.8);
    border-color: rgba($blue, 0.8);
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $blue;
  border-color: $blue;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: white !important;
}

.mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
  color: $red;
}

.text-primary {
  color: $blue !important;
}

html {
  font-size: 100%;
}

body {
  margin: 0;
  height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-display: swap;
  font-style: normal;
}

app-sidebar {
  position: relative;
  width: 6rem;
  @include sm {
    width: 0;
  }
}

.all-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  background-image: url('assets/images/bg_trame.svg');
  overflow: hidden;
}

.content-container {
  width: 100%;
  // flex-grow: 1;
  flex: 1 1 auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .route-content {
    height: calc(100% - 100px);
  }
}

.h-100 {
  min-height: 100% !important;
  height: auto !important;
}

.sub_header {
  height: $sub-header-height;
  background-color: $white !important;
  box-shadow: inset 0 -1px 0 0 $light-grey;
  padding: 0.5rem 3rem 0.5rem 3rem !important;

  @include sm {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
  }

  & .title_sub_header {
    //padding-left: $search_container_width;
    color: $dark-blue;
    font-size: 1rem;
    @include xs {
    }
  }

  & .fill-space {
    flex: 1 1 auto;
  }
}

.btn {

  font-display: swap;
  font-style: normal;
}

.sticky-btn {
  @include sm {
    position: fixed;
    z-index: 99;
    bottom: 2em;
    right: 2em;
  }
}

.mat-toolbar {
}

button.mat-mdc-button {
  border: solid 1px;
  border-color: #6cadce;
}

app-tableau-de-bord-card {
  .progress {
    display: initial;
    text-align: center;
    background-color: #f8fced;
    color: #94c01f !important;
    border-radius: 11px;
    padding: 3px 7px;

    &.bad-progress {
      background-color: #fef3eb;
      color: #e20714 !important;
    }
  }

  .main-label,
  .item-label,
  .progress {
    font-size: 0.9rem;
    color: #778699;

    @include sm {
      font-size: 0.5rem;
    }

    @include md {
      font-size: 0.8rem;
    }

    @include lg {
      font-size: 0.5rem;
    }
    margin: 0;
  }

  .main-value {
    font-size: 3rem;
    margin: 0;

    @include lg {
      font-size: 2rem;
    }
  }

  .item-value {
    font-size: 2.5rem;
    margin: 0;

    @include lg {
      font-size: 1.75rem;
    }

    @include md {
      font-size: 2rem;
    }

    @include sm {
      font-size: 1.75rem;
    }
  }
}

.mouse-click .ag-body-viewport {
  cursor: pointer;
}

.tiny-text {

  font-weight: 300;
  color: $blue-grey;
  font-size: small;
}

.mat-mdc-card:not([class*='mat-elevation-z']) {
  box-shadow: -2px 2px 5px $grey;
  @include sm {
    box-shadow: none;
    border-radius: 0;
    margin: 0 !important;
  }
}

.mat-select-panel mat-option.mat-mdc-option {
  height: unset;
}

button {
  outline: none !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.no-underline .mat-form-field-underline {
  display: none;
}

.cdk-overlay-pane.dialog {
  @include sm {
    height: 100vh;
    width: 100vw !important;
    max-width: none !important;
    border: none;

    & .mat-mdc-dialog-container {
      border-radius: inherit;
      padding: 24px 0;
    }

    & mat-dialog-content {
      max-height: inherit;
    }
  }
}

.cdk-overlay-pane.dialog-large {
  width: 90vw !important;
  @include sm {
    height: 100vh;
    width: 100vw !important;
    max-width: none !important;
    border: none;

    & .mat-mdc-dialog-container {
      border-radius: inherit;
      padding: 24px 0;
    }

    & mat-dialog-content {
      max-height: inherit;
    }
  }
}

.no-shadow {
  box-shadow: none !important;
}

.center-in-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-btn {
  padding: 18px;
  border: 1px solid $red;
  color: $red;
  border-radius: 10px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
}

.delete-btn:hover {
  color: white;
  background: $red;
}

.pointer:hover {
  cursor: pointer;
}

// custom mat tab css
.mat-host {
  .col-host {
    div {
      height: 100% !important;
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-body-wrapper {
    height: 100% !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  mat-tab-body-wrapper {
    height: 100% !important;
  }
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.mt-4_5 {
  margin-top: 2rem !important;
}

.mat-mdc-tooltip {
  font-size: 12pt !important;
}

.star:after {
  content: ' *';
  color: red;
}

label {
  font-weight: 600;
  color: $dark-blue;
  font-size: 1rem;
  margin-bottom: 0;
}

.height-100 {
  height: 100% !important;
}

.h-90 {
  height: 90% !important;
}

.h-85 {
  height: 85% !important;
}

.h-80 {
  height: 80% !important;
}

.disabled {
  background-color: #e9ecef !important;
  pointer-events: none !important;
  opacity: 1 !important;
}

.disabled-button {
  pointer-events: none !important;
}

.custom-select {
  background: none;
  padding-right: 0.75rem;
}

.w-45 {
  width: 45%;
}

.w-40 {
  width: 40%;
}

.important-text {
  font-weight: 600;
  font-size: larger;
  color: $dark-blue;
  font-size: 1rem;
  line-height: 1.2;
}

.parcours-icon {
  width: 48px;
  height: 48px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5em;

  img {
    width: 36px;
    height: 36px;
  }

  &.ambition {
    background-color: #eb6608;
    border-color: #eb6608;
  }

  &.decouverte {
    background-color: #fab900;
    border-color: #fab900;
  }

  &.emploi {
    background-color: #009eeb;
    border-color: #009eeb;
  }

  &.passe_decisive {
    background-color: #94c01f;
    border-color: #94c01f;
  }
}

.header-icon-grid {
  font-size: 20pt;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.eventListItem {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  padding: 16px;
}

.btn-fit {
  width: fit-content !important;
}

.dashboard-date-picker {
  button {
    width: 40px !important;
    height: 40px !important;
  }
}

.label-with-btn {
  display: flex;
  align-items: center;
}


mat-icon {
  font-family: Material Icons, serif;
}

.info-liste-card {
  color: $dark-blue;
}

.custom-theme circle {
  stroke: $blue;
}

.colored-btn {
  background-color: $blue !important;

  &.mat-mdc-button {
    color: $white !important;
  }
}


