$ag-icons-path: '~@ag-grid-community/core/dist/styles/ag-theme-balham/icons/';

$toolbar-height: 6.25rem;
$search_container_width: 1rem;
$search_container_width_md: 3.6rem;

$sidebar-expand: 16rem;
$sidebar-short: 6rem;

$sub-header-height: 4.5rem;

//Media query

// Extra small devices
$screen-xs-min: 576px;

// Small devices
$screen-sm-min: 767px;

// Medium devices
$screen-md-min: 992px;

// Large devices
$screen-lg-min: 1200px;

// Extra small devices
@mixin xs {
  @media (max-width: #{$screen-xs-min}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin rwd($screen) {
  @media (max-width: $screen+'px') {
    @content;
  }
}

$base-width: 1px;

@for $i from 1 through 100 {
  .gap-#{$i} {
    gap: $base-width * $i;
  }
}

$base-purcent: 1%;

@for $i from 1 through 100 {
  .h-#{$i} {
    height: $base-purcent * $i !important;
  }
}

@for $i from 1 through 100 {
  .w-#{$i} {
    width: $base-purcent * $i !important;
  }
}
